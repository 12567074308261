import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';

//import redux dependecies
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import reducers from "./reducers";

// PWA
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Loader from './components/reusables/Loader'

const store = createStore(reducers, compose(applyMiddleware(thunk)));

ReactDOM.render(
	<React.StrictMode>
		<CacheBuster
			currentVersion={version}
			isEnabled={true} //If false, the library is disabled.
			isVerboseMode={true} //If true, the library writes verbose logs to console.
			loadingComponent={<Loader />}
		>
			<Provider store={store}>
				<App />
			</Provider>
		</CacheBuster>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();